import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  reviewManagementHero,
  reviewManagementTrusted,
  reviewManagementWhiteGlove,
  reviewManagementLargeFeatures,
  reviewManagementBusinessTypesCar,
  reviewManagementVideo,
  reviewManagementSolutionsSection,
  reviewManagementTestimonials,
  reviewManagementCTA,
  reviewSolutionsSlides,
  // reviewManagementFaqs,
} from "../../../data/product-pages/review-management-data";
import Hero from "../../../components/Hero/hero";
import { argantiSlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "review-management-pp-wg.png";

const reviewManagement = () => {
  return (
    <Layout>
      <SEO
        title="Online Review Management Software | Manage Customer Reviews | SpotOn"
        description="Monitor, respond to and improve your online reviews with ease with SpotOn's online review management software.  Get a demo!"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/review-management/"
      />
      <Hero sectionData={reviewManagementHero} circleBg={false} />
      <TrustedNumbers numbersArray={reviewManagementTrusted} />
      <WhiteGlove
        sectionData={reviewManagementWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={reviewManagementLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={reviewManagementBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={argantiSlides} />
      <VideoSection sectionData={reviewManagementVideo} />
      <PartnerLogos />
      <ProductLines
        sectionData={reviewManagementSolutionsSection}
        productSlides={reviewSolutionsSlides}
      />
      <TestmonialReviews sectionData={reviewManagementTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={reviewManagementCTA} />
      {/* <Faqs sectionData={reviewManagementFaqs} /> */}
    </Layout>
  );
};

export default reviewManagement;
