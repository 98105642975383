/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const reviewManagementHero = {
  title: "Know what customers are saying with online review management",
  subtext: "Monitor, respond to, and improve your online reviews with ease.",
  heroImg: "review-management-hero.png",
  imageClassName: "top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/review-management/demo",
  },
  fatTitle: "Reviews",
};

export const reviewManagementTrusted = [
  {
    title: "88%",
    subtext:
      "Customers trust online reviews as much as personal recommendations",
  },
  {
    title: "67%",
    subtext: "Customers are scared away by 1 to 3 negative reviews",
  },
  {
    title: "95%",
    subtext:
      "Unhappy customers will return if their complaint is resolved quickly",
  },
  {
    title: "70%",
    subtext: "Customers will leave a review if prompted",
  },
];

export const reviewManagementWhiteGlove = {
  mainTitle: "Digital tools backed by 24/7 personal support",
  title: "A stress-free experience",
  content:
    "Online reviews are important to build your reputation, but they can be a hassle to manage. We’re here to make it easy. Our team of experts will set up your review management dashboard so you can easily access all of your past reviews on popular sites like Yelp, TripAdvisor, Facebook, and Google, and get real-time alerts whenever new reviews are posted. ",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/review-management/demo",
  },
};

export const reviewManagementLargeFeatures = {
  sectionTitle: "Take control of your online reputation",
  featureBlocks: [
    {
      blockTitle: "Let your customers know they've been heard",
      blockImg: "review-management-a.png",
      blockSubTitle:
        "Your customers have opinions. Good or bad, it’s important to know what they’re saying—and to respond when necessary.",
      blockList: [
        "See all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Monitor your reputation as it improves over time",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/review-management/demo",
      },
    },
    {
      blockTitle: "Work online or in-app",
      blockSubTitle:
        "Finding time to manage and respond to reviews can be tricky, but with SpotOn you can do it on the go. Just log in to your SpotOn dashboard from a computer or use our free mobile app to access your reviews anytime.",
      blockImg: "review-management-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/review-management/demo",
      },
    },
    {
      blockTitle: "Make the most out of every opportunity to grow",
      blockSubTitle:
        "Nurture relationships with your customers by using the smartest digital tools.",
      blockImg: "review-management-c.png",
      blockList: [
        "Attract new customers by offering limited-time deals",
        "Drive repeat visits with optional digital \n" + "loyalty rewards",
        "Email marketing",
        "Facebook campaigns",
        "Generate more positive reviews with automatic review requests",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/review-management/demo",
      },
    },
  ],
};

export const reviewManagementBusinessTypesCar = {
  title: "Flexible technology customized for your business",
  subtext:
    "No matter what type of business you run, your online reputation matters. We’ll keep tabs on the review sites most important to you.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const reviewManagementVideo = {
  title: "Make every interaction count",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "3CYEMPljHCE",
};

export const reviewManagementSolutionsSection = {
  title: "Grow even more with these integrated tools",
  subText: `Save time and grow your business with fully integrated payments, loyalty rewards, and marketing campaigns from SpotOn.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const reviewManagementTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const reviewManagementIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept reviewManagements and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const reviewManagementCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/review-management/demo",
  },
};

export const reviewManagementFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const reviewSolutionsSlides = [
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
];
